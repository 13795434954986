import {
  Alert,
  Button,
  Input,
  Result,
  Space,
  Steps,
  Table,
  Typography,
  Upload,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import csv from "csvtojson";
import _ from "lodash";

const { Paragraph, Text } = Typography;

const { Dragger } = Upload;

import { Modal, ModalProps } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { RcFile } from "antd/lib/upload";
import { CustomButton } from "specifics/button";
import { Option } from "antd/lib/mentions";
import { Form, useEffectSkipFirst, useForm } from "utils/hooks";
import { CustomSelectField, RequiredLabel } from "specifics/input";
import {
  Order,
  OrderCharterType,
  OrderCharterTypes,
  OrderDetail,
  OrderType,
  OrderUnitTypes,
} from "entities/order";
import { DeliveryCompany } from "entities/delivery_company";
import { Consignor } from "entities/consignor";
import { ColumnsType } from "antd/es/table";
import { useTable } from "specifics/use_table";
import {
  getOrderCharterTypeLabel,
  OrderTypeView,
} from "components/orders/orders_view";
import dayjs from "dayjs";
import { CustomTableText } from "specifics/table_text";
import { usePostOrdersApi } from "api/order";
import { GlobalStateContext } from "contexts/global_state_context";
import { CustomTable } from "specifics/table";
import { SelectField } from "components/shared/input";
import { hankakuKatakanaToHiragana } from "utils/util";

type Column = { title: string; dataIndex: string; key: string };
type FormType = {
  index: number;
  key: string;
  masterColumn: string;
  csvColumn?: string;
  required?: boolean;
};

type MasterColumn = {
  columnName: string;
  required?: boolean;
  validation: () => (string | undefined)[];
};

export const OrderUploadCsvFileModal = ({
  loading,
  orderType,
  deliveryCompanies,
  consignors,
  onOk,
  onCancel,
  onComplete,
  ...props
}: ModalProps & {
  loading: boolean;
  orderType?: OrderType;
  deliveryCompanies: DeliveryCompany[];
  consignors: Consignor[];
  onComplete?: () => void;
}) => {
  const [csvData, setCsvData] = useState<any[]>([]);
  const [columns, setColumns] = useState<Array<Column>>([]);
  const postApi = usePostOrdersApi();
  const globalState = useContext(GlobalStateContext);
  const ENCODING_CANDIDATES = [
    "UTF-8",
    "Shift_JIS",
    "ISO-2022-JP",
    "EUC-JP",
    "EUC-KR",
    "Big5",
    "gb18030",
    "GBK",
  ] as const;
  type EncodingCandidates = (typeof ENCODING_CANDIDATES)[number];
  const encodingForm = useForm<{ encode?: EncodingCandidates }>({});

  useEffect(() => {
    const encode = localStorage.getItem("knewit-csv-encoding");
    if (encode) {
      encodingForm.updateObject("encode", encode);
    } else {
      encodingForm.updateObject("encode", "UTF-8");
    }
  }, []);

  useEffectSkipFirst(() => {
    globalState.setLoading(postApi.loading);
    if (postApi.isSuccess()) {
      if (onComplete) {
        handleInitialize();
        onComplete();
      }
    }
  }, [postApi.loading]);

  const masterColumns: MasterColumn[] = [
    {
      columnName: "受注番号",
      required: true,
      validation: () => {
        return csvData.map(() => undefined);
      },
    },
    {
      columnName: "受注日",
      required: true,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "受注日");
          if (data && !data?.match(/\d{4}\/\d{2}\/\d{2}/))
            return "「YYYY/MM/DD」の形式で入力してください。";
          return;
        });
      },
    },
    {
      columnName: "荷主名称",
      required: true,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "荷主名称");
          if (data && !findConsignorIdByName(data))
            return `${data}という名前の荷主は登録されていません。`;
          return;
        });
      },
    },
    {
      columnName: "積み/降し",
      required: true,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "積み/降し");
          if (data && data !== "積" && data !== "降")
            return '"積"または"降"を入力してください。';
          return;
        });
      },
    },
    {
      columnName: "積み日/降し日",
      required: true,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "積み日/降し日");
          if (data && !data?.match(/\d{4}\/\d{2}\/\d{2}/))
            return "「YYYY/MM/DD」の形式で入力してください。";
          return;
        });
      },
    },
    {
      columnName: "行先名称",
      required: true,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "行先名称");
          if (data && !findDeliveryCompanyIdByName(data))
            return `${data}という名前の行先は登録されていません。`;
          return;
        });
      },
    },
    {
      columnName: "重量",
      required: true,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "重量");
          if (data && !data?.match(/^\d*\.?\d+$/))
            return "正の整数または少数のみを入力してください。";
          return;
        });
      },
    },
    {
      columnName: "自社/傭車",
      required: true,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "自社/傭車");
          if (data && data !== "自社" && data !== "傭車")
            return '"自社"または"傭車"を入力してください。';
          return;
        });
      },
    },
    {
      columnName: "品名",
      required: false,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "品名");
          if (data && typeof data !== "string") return "文字列を入力してください。";
          return;
        });
      },
    },
    {
      columnName: "工法",
      required: false,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "工法");
          if (data && typeof data !== "string") return "文字列を入力してください。";
          return;
        });
      },
    },
    {
      columnName: "数量",
      required: false,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "数量");
          if (data && !data?.match(/^\d+$/)) return "数値のみを入力してください。";
          return;
        });
      },
    },
    {
      columnName: "単位",
      required: false,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "単位");
          if (data && !["個", "本", "枚"].includes(data))
            return '"個", "本", "枚"のいずれかを入力してください。';
          return;
        });
      },
    },
    {
      columnName: "長さ",
      required: false,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "長さ");
          if (data && !data?.match(/^\d*\.?\d+$/))
            return "正の整数または少数のみを入力してください。";
          return;
        });
      },
    },
    {
      columnName: "最長長さ",
      required: false,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "最長長さ");
          if (data && !data?.match(/^\d*\.?\d+$/))
            return "正の整数または少数のみを入力してください。";
          return;
        });
      },
    },
    {
      columnName: "着時間指定",
      required: false,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "着時間指定");
          if (data && typeof data !== "string") return "文字列を入力してください。";
          return;
        });
      },
    },
    {
      columnName: "備考",
      required: false,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "備考");
          if (data && typeof data !== "string") return "文字列を入力してください。";
          return;
        });
      },
    },
    {
      columnName: "車格",
      required: true,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "車格");
          if (data && !data?.match(/^\d+$/))
            return "正の整数のみを入力してください。";
          return;
        });
      },
    },
    {
      columnName: "金額",
      required: true,
      validation: () => {
        return csvData.map((item) => {
          const data = getDataByMasterColumn(item, "金額");
          if (data && !data?.match(/^\d+$/)) return "数値のみを入力してください。";
          return;
        });
      },
    },
  ].filter((item) => {
    if (orderType === 0) return true;
    else if (orderType === 1)
      return item.columnName !== "車格" && item.columnName !== "金額";
    else {
      return false;
    }
  });

  const getDataByMasterColumn = (item: any, masterColumn: string) => {
    const itemKey = asignCsvColumnAndOrderColumnForm.object.find(
      (colData) => colData.masterColumn === masterColumn
    )?.csvColumn;
    if (itemKey) return item[itemKey];
    return "";
  };

  const findDeliveryCompanyIdByName = (name: string) => {
    if (loading) return undefined;
    const normalizeName = (inputName?: string) => {
      if (!inputName) return;
      let normalizedName = inputName
        .replace(/[\s\u3000]/g, "") // 半角及び全角スペースを除去
        .replace(/株式会社|㈱|（株）|\(株\)/g, "") // 「株式会社」と「㈱」と「（株）」と「(株)」を除去
        .replace(/[\u30a1-\u30f6]/g, (match) =>
          String.fromCharCode(match.charCodeAt(0) - 0x60)
        ); // 全角カタカナを全角ひらがなに
      normalizedName = hankakuKatakanaToHiragana(normalizedName); // 半角カタカナを全角ひらがなに
      return normalizedName;
    };

    const dc = deliveryCompanies.find((dc) => {
      return normalizeName(dc.name ?? "") === normalizeName(name);
    });
    if (dc) return dc.id;
    else return undefined;
  };

  const findConsignorIdByName = (name: string) => {
    if (loading) return undefined;
    const consignor = consignors.find((consignor) => consignor.name === name);
    if (consignor) return consignor.id;
    else return undefined;
  };

  const groupByData = (inputList: any[]) => {
    const outputList: Order[] = [];

    let no = 0;
    inputList.forEach((item) => {
      if (Object.values(item).every(value => !value)) {
        // 空行時の処理
        outputList.push({});
        return;
      }
      const existingItem = outputList.find(
        (outputItem) =>
          outputItem.orderNo === getDataByMasterColumn(item, "受注番号")
      );
      const pointType =
        getDataByMasterColumn(item, "積み/降し") === "積"
          ? 2
          : getDataByMasterColumn(item, "積み/降し") === "降"
            ? 1
            : undefined;
      const orderDetail: OrderDetail = {
        deliveryCompanyId: findDeliveryCompanyIdByName(
          getDataByMasterColumn(item, "行先名称")
        ),
        loadWeight: Number(getDataByMasterColumn(item, "重量")),
        deliveryDate: getDataByMasterColumn(item, "積み日/降し日"),
        pointType,
        no: 0,
        productName: getDataByMasterColumn(item, "品名"),
        method: getDataByMasterColumn(item, "工法"),
        quantity: Number(getDataByMasterColumn(item, "数量")),
        unit: getDataByMasterColumn(item, "単位"),
        length: Number(getDataByMasterColumn(item, "長さ")),
        maxLength: Number(getDataByMasterColumn(item, "最長長さ")),
        arrivalTimeSpecified: getDataByMasterColumn(item, "着時間指定"),
        memo: getDataByMasterColumn(item, "備考"),
      };
      const charterType: OrderCharterType | undefined = Object.keys(
        OrderCharterTypes
      )
        .map((key) => Number(key) as OrderCharterType)
        .find(
          (key) =>
            OrderCharterTypes[key] === getDataByMasterColumn(item, "自社/傭車")
        );

      if (existingItem) {
        no = no + 1;
        existingItem.orderDetails?.push({ ...orderDetail, no });
      } else {
        no = 0;
        outputList.push({
          orderType,
          orderNo: getDataByMasterColumn(item, "受注番号"),
          orderDate: getDataByMasterColumn(item, "受注日"),
          consignorId: findConsignorIdByName(
            getDataByMasterColumn(item, "荷主名称")
          ),
          charterType,
          price:
            orderType === 0 ? getDataByMasterColumn(item, "金額") : undefined,
          weight:
            orderType === 0 ? getDataByMasterColumn(item, "車格") : undefined,
          orderDetails: [orderDetail],
        });
      }
    });

    return outputList;
  };

  const Modes = ["Upload", "View", "Column", "Check", "Send"] as const;
  type Mode = (typeof Modes)[number];
  const [mode, setMode] = useState<Mode>("Upload");

  const handleUpload = async (file: RcFile) => {
    const reader = new FileReader();
    reader.onload = async (event: any) => {
      const jsonData = await csv().fromString(event.target.result);
      setCsvData(jsonData);
      if (jsonData.length > 0) {
        setColumns(
          Object.keys(jsonData[0]).map((key) => ({
            title: key,
            dataIndex: key,
            key: key,
            width: 100,
          }))
        );
      }
    };
    reader.readAsText(file, encodingForm.object.encode);
    setMode("View");
  };

  const asignCsvColumnAndOrderColumnForm = useForm<FormType[]>([]);

  const orderData: Order[] = useMemo(() => {
    if (!loading) {
      return groupByData(csvData);
    } else {
      return [];
    }
  }, [
    csvData,
    JSON.stringify(asignCsvColumnAndOrderColumnForm.object),
    loading,
  ]);

  const validationResults: { columnName: string; result: any[] }[] =
    useMemo(() => {
      return masterColumns.map((masterColumn) => {
        return {
          columnName: masterColumn.columnName,
          result: masterColumn.validation(),
        };
      });
    }, [orderData]);

  const isOk = (): boolean => {
    return validationResults.every((result) =>
      result.result.every((message) => !message)
    );
  };

  const table = useTable<Order>();
  const orderColumns: ColumnsType<Order> = [
    {
      ...table.baseColumn("orderNo"),
      title: "受注番号",
      render: (item: Order) => item.orderNo ?? "--",
      width: 150,
    },
    {
      ...table.baseColumn("orderType"),
      title: "カテゴリ",
      width: "130px",
      render: (item: Order) => OrderTypeView(item),
    },
    {
      ...table.baseColumn("orderDate"),
      render: (item: Order) =>
        dayjs(item.orderDate).format("YYYY/MM/DD") ?? "--",
      title: "受注日",
      width: "120px",
    },
    {
      ...table.baseColumn("consignor.name"),
      title: "荷主",
      width: "240px",
      render: (item: Order) => (
        <Space size={0}>
          <CustomTableText width={190}>
            {
              consignors.find((consignor) => consignor.id === item.consignorId)
                ?.name
            }
          </CustomTableText>
        </Space>
      ),
    },
    {
      key: "loadingDate",
      title: "積み日",
      width: "100px",
      render: (item: Order) => {
        const list = item.orderDetails?.filter(
          (orderDetail) => orderDetail.pointType === 2
        );
        if (!list?.length) {
          return "--";
        }
        const date = list[0]?.deliveryDate;
        return dayjs(date).format("MM/DD");
      },
    },
    {
      key: "unloadingDate",
      title: "降し日",
      width: "100px",
      render: (item: Order) => {
        const list = item.orderDetails?.filter(
          (orderDetail) => orderDetail.pointType === 1
        );
        if (!list?.length) {
          return "--";
        }
        const date = list[0]?.deliveryDate;
        return dayjs(date).format("MM/DD");
      },
    },
    {
      key: "loadingDeliveryCompany",
      title: "積み地",
      width: "240px",
      render: (item: Order) => {
        return (
          <Space direction="vertical" size={0}>
            {item.orderDetails
              ?.filter((detail) => detail.pointType === 2 ?? [])
              ?.map((detail, i) => (
                <Space size={0} key={`LoadingDeliveryCompanyName${i}`}>
                  <CustomTableText width={190}>
                    {
                      deliveryCompanies.find(
                        (dc) => dc.id === detail?.deliveryCompanyId
                      )?.name
                    }
                  </CustomTableText>
                </Space>
              ))}
          </Space>
        );
      },
    },
    {
      key: "loadingWeight",
      title: "重量/キログラム",
      width: "140px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails
              ?.filter((od) => od.pointType === 2)
              ?.map((od) => (
                <div key={od.id}>{od.loadWeight}kg</div>
              ))}
          </>
        );
      },
    },
    {
      key: "unloadingDeliveryCompany",
      title: "降し地",
      width: "240px",
      render: (item: Order) => {
        return (
          <Space direction="vertical" size={0}>
            {item.orderDetails
              ?.filter((detail) => detail.pointType === 1 ?? [])
              ?.map((detail, i) => (
                <Space size={0} key={`UnloadingDeliveryCompanyName${i}`}>
                  <CustomTableText width={190}>
                    {
                      deliveryCompanies.find(
                        (dc) => dc.id === detail?.deliveryCompanyId
                      )?.name
                    }
                  </CustomTableText>
                </Space>
              ))}
          </Space>
        );
      },
    }, {
      key: "productName",
      title: "品名",
      width: "200px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails?.map((detail, i) => (
              <div key={`ProductName${i}`}>{detail.productName ?? "--"}</div>
            ))}
          </>
        );
      },
    },
    {
      key: "method",
      title: "工法",
      width: "150px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails?.map((detail, i) => (
              <div key={`Method${i}`}>{detail.method ?? "--"}</div>
            ))}
          </>
        );
      },
    },
    {
      key: "quantity",
      title: "数量",
      width: "100px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails?.map((detail, i) => (
              <div key={`Quantity${i}`}>{detail.quantity ?? "--"}</div>
            ))}
          </>
        );
      },
    },
    {
      key: "unit",
      title: "単位",
      width: "100px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails?.map((detail, i) => (
              <div key={`Unit${i}`}>{detail.unit ?? "--"}</div>
            ))}
          </>
        );
      },
    },
    {
      key: "length",
      title: "長さ",
      width: "100px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails?.map((detail, i) => (
              <div key={`Length${i}`}>{detail.length ?? "--"}</div>
            ))}
          </>
        );
      },
    },
    {
      key: "maxLength",
      title: "最長長さ",
      width: "100px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails?.map((detail, i) => (
              <div key={`MaxLength${i}`}>{detail.maxLength ? `${detail.maxLength}m` : "--"}</div>
            ))}
          </>
        );
      },
    },
    {
      key: "arrivalTimeSpecified",
      title: "着時間指定",
      width: "150px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails?.map((detail, i) => (
              <div key={`ArrivalTimeSpecified${i}`}>{detail.arrivalTimeSpecified ?? "--"}</div>
            ))}
          </>
        );
      },
    },
    {
      key: "memo",
      title: "備考",
      width: "150px",
      render: (item: Order) => {
        return (
          <>
            {item.orderDetails?.map((detail, i) => (
              <div key={`Memo${i}`}>{detail.memo ?? "--"}</div>
            ))}
          </>
        );
      },
    },
    {
      ...table.baseColumn("price"),
      title: "金額",
      render: (item: Order) => `${item.price}円`,
      width: 100,
    },
    {
      ...table.baseColumn("weight"),
      title: "車格",
      render: (item: Order) => `${item.weight}t`,
      width: 100,
    },
    {
      ...table.baseColumn("charterType"),
      title: "車両(自社/傭車)",
      render: (item: Order) => getOrderCharterTypeLabel(item.charterType),
      width: 100,
    },
  ].filter((col) => {
    if (orderType === 0) {
      return true;
    } else if (orderType === 1) {
      return col.title !== "金額" && col.title !== "車格";
    }
  });

  const handleInitialize = () => {
    setCsvData([]);
    setMode("Upload");
  };

  if (orderType === undefined) {
    return <></>;
  } else {
    return (
      <Modal
        title="CSVファイルのアップロード"
        style={{ top: 16 }}
        width={"100vw"}
        onCancel={(e) => {
          onCancel && onCancel(e);
          handleInitialize();
        }}
        footer={[
          <Button
            key={"cancel"}
            onClick={(e: any) => {
              if (Modes.indexOf(mode) === 0 && onCancel) {
                onCancel(e);
                handleInitialize();
              } else {
                setMode(Modes[Modes.indexOf(mode) - 1]);
              }
            }}
          >
            {Modes.indexOf(mode) === 0 ? "閉じる" : "戻る"}
          </Button>,
          <Button
            onClick={() => {
              if (Modes.indexOf(mode) < Modes.length - 1) {
                setMode(Modes[Modes.indexOf(mode) + 1]);
              }
              if (mode === "Check") {
                postApi.execute(orderData);
              }
            }}
            key={"cancel"}
            disabled={
              (Modes.indexOf(mode) === 0 && csvData.length === 0) ||
              (mode === "Column" &&
                !asignCsvColumnAndOrderColumnForm.object.every((item) =>
                  item.required ? item.csvColumn : true
                )) ||
              (mode === "Check" && !isOk())
            }
            style={{
              display:
                Modes.indexOf(mode) === Modes.length - 1 ? "none" : "initial",
            }}
          >
            {mode === "Check" ? "送信" : "次へ"}
          </Button>,
        ]}
        {...props}
      >
        <Space
          direction="vertical"
          style={{
            width: "calc(100vw - 80px)",
            height: "calc(100vh - 209px)",
            overflowY: "scroll",
          }}
        >
          <Steps
            size="small"
            style={{ padding: 4 }}
            current={Modes.indexOf(mode)}
            items={[
              { title: "CSVアップロード" },
              { title: "ファイルの確認" },
              { title: "列の割り当て" },
              { title: "確認" },
            ]}
          />
          {mode === "Upload" && (
            <div
              style={{
                height: 400,
                width: "100%",
              }}
            >
              <div style={{ paddingTop: 16 }}>
                <div>エンコード</div>
                <SelectField
                  form={encodingForm}
                  attr="encode"
                  selectItems={ENCODING_CANDIDATES.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={(e) => {
                    encodingForm.updateObject("encode", e);
                    localStorage.setItem("knewit-csv-encoding", e);
                    handleInitialize();
                  }}
                />
              </div>
              <Dragger
                showUploadList={false}
                maxCount={1}
                name="file"
                accept="text/csv"
                beforeUpload={handleUpload}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  クリックもしくはドラッグ&ドロップしてファイルをアップロードしてください。
                </p>
              </Dragger>
            </div>
          )}
          {mode === "View" && (
            <Space direction="vertical">
              <Table
                style={{ width: "calc(100vw - 80px)" }}
                scroll={{
                  y: "calc(100vh - 390px)",
                  x: "40%",
                }}
                dataSource={csvData}
                columns={columns}
              />
            </Space>
          )}
          {mode === "Column" && (
            <Space direction="vertical">
              {!asignCsvColumnAndOrderColumnForm.object.every((item) =>
                item.required ? item.csvColumn : true
              ) && (
                  <Alert type="error" message={"未選択のCSVの列があります。"} />
                )}
              <SelectColumnTable
                orderType={orderType}
                asignCsvColumnAndOrderColumnForm={
                  asignCsvColumnAndOrderColumnForm
                }
                masterColumns={masterColumns}
                csvColumns={columns}
              />
            </Space>
          )}
          {mode === "Check" && (
            <>
              <Result
                status={isOk() ? "info" : "error"}
                title={
                  isOk()
                    ? "CSVファイルから受注データを読み取ることができました。"
                    : "ファイルのデータが不適切です。"
                }
                subTitle={
                  isOk()
                    ? "以下の内容で受注データが登録されます。問題が無ければ、右下の送信ボタンを押してください。"
                    : "エラーメッセージを参照してCSVファイルの内容を修正し、再度アップロードしてください。"
                }
                extra={
                  !isOk() && [
                    <CustomButton
                      onClick={() => setMode("View")}
                      type="primary"
                      key="back"
                    >
                      アップロードしたファイルを確認する
                    </CustomButton>,
                    <CustomButton
                      onClick={() => setMode("Upload")}
                      key="reupload"
                    >
                      ファイルを再びアップロードする
                    </CustomButton>,
                  ]
                }
              >
                {isOk() ? null : (
                  <div className="desc">
                    {validationResults.map((validationResult) => {
                      if (validationResult.result.every((result) => !result))
                        return <></>;
                      return (
                        <>
                          <Paragraph>
                            <Text
                              strong
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {validationResult.columnName}(CSVの列名:{" "}
                              {
                                asignCsvColumnAndOrderColumnForm.object.find(
                                  (item) =>
                                    item.masterColumn ===
                                    validationResult.columnName
                                )?.csvColumn
                              }
                              )
                            </Text>
                          </Paragraph>
                          {validationResult.result.map((result, index) => {
                            if (result) {
                              return (
                                <Paragraph key={result}>
                                  <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                                  {index + 1}行目: {result}
                                </Paragraph>
                              );
                            }
                          })}
                        </>
                      );
                    })}
                  </div>
                )}
              </Result>
              {isOk() && (
                <Space
                  direction="vertical"
                  style={{ width: "calc(100vw - 80px)" }}
                >
                  <CustomTable
                    tableKey="order_csv_import"
                    scroll={{ x: "20%" }}
                    table={table}
                    columns={orderColumns}
                    dataSource={orderData}
                  />
                </Space>
              )}
            </>
          )}
        </Space>
      </Modal>
    );
  }
};

const SelectColumnTable = ({
  csvColumns,
  masterColumns,
  asignCsvColumnAndOrderColumnForm,
  orderType,
}: {
  csvColumns: Column[];
  masterColumns: MasterColumn[];
  asignCsvColumnAndOrderColumnForm: Form<FormType[]>;
  orderType: OrderType;
}) => {
  useEffect(() => {
    const cache: FormType[] = JSON.parse(
      localStorage.getItem(`csvImport/OrderColumms/${orderType}`) ?? "[]"
    );
    asignCsvColumnAndOrderColumnForm.set(
      masterColumns.map((col, index) => {
        const csvColumn = cache.find(
          (data) => data.masterColumn === col.columnName
        )?.csvColumn;
        return {
          index,
          key: col.columnName,
          masterColumn: col.columnName,
          csvColumn:
            csvColumn && csvColumns.map((col) => col.title).includes(csvColumn)
              ? csvColumn
              : undefined,
          required: col.required,
        };
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      localStorage.setItem(
        `csvImport/OrderColumms/${orderType}`,
        JSON.stringify(asignCsvColumnAndOrderColumnForm.object)
      );
    };
  }, [JSON.stringify(asignCsvColumnAndOrderColumnForm.object)]);

  const columns = [
    {
      title: "受注の列",
      width: 200,
      render: (record: FormType) => {
        return (
          <Space>
            {record.masterColumn}
            {record.required && <RequiredLabel />}
          </Space>
        );
      },
    },
    {
      title: "CSVの列",
      width: 150,
      render: (record: FormType) => (
        <CustomSelectField
          form={asignCsvColumnAndOrderColumnForm}
          attr={[record.index, "csvColumn"]}
          selectItems={csvColumns.map((csvColumn) => ({
            label: csvColumn.title,
            value: csvColumn.key,
          }))}
        />
      ),
    },
  ];

  return (
    <Table
      scroll={{
        y: "calc(100vh - 390px)",
      }}
      pagination={false}
      dataSource={asignCsvColumnAndOrderColumnForm.object}
      columns={columns}
    />
  );
};
